<template>
  <div>
    <CContainer>
      <CRow>
        <CCol md="6">
          <CCardGroup>
            <CCard>
              <CCardHeader>
                <h3 v-if="this.tigerFactData.id">
                  Редактирование факта о тигре
                </h3>
                <h3 v-else>Создание факта о тигре</h3>
              </CCardHeader>
              <CCardBody>
                <CForm @submit.prevent="handleSubmit">
                  <CInput
                    v-model="tigerFactData.lead"
                    label="Заголовок"
                    placeholder=""
                    @input="requestTigerFactUpdate"
                  >
                  </CInput>
                  <CTextarea
                    v-model="tigerFactData.text"
                    label="Содержание"
                    placeholder=""
                    rows="10"
                    @input="requestTigerFactUpdate"
                  />
                  <CRow>
                    <CCol>
                      <CButton
                        v-if="this.tigerFactData.id"
                        color="success"
                        type="submit"
                      >
                        Сохранить
                      </CButton>
                      <CButton v-else color="success" type="submit">
                        Создать
                      </CButton>
                    </CCol>
                    <CButton
                      v-if="tigerFactData.id"
                      color="success"
                      type="button"
                      @click="
                        router.push({
                          name: 'tiger-fact-create',
                        })
                      "
                    >
                      Добавить ещё
                    </CButton>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { makeCoreUICSelectList, updateViewURL } from "../helpers";
import { debounce } from "lodash";
import { router } from "../router";
export default {
  name: "TigerFactForm",
  data() {
    return {
      router,
      shareImage: null,
      tigerFactData: {
        id: this.$route.params.id,
        lead: null,
        text: null,
      },
    };
  },

  created() {
    this.storeSubscription = this.$store.subscribe((mutation) => {
      if (mutation.type === "tigerFacts/getByIdSuccess") {
        this.initTigerFactForm(mutation.payload);
      } else if (mutation.type === "tigerFacts/createSuccess") {
        this.initTigerFactForm(mutation.payload);
        updateViewURL(router, {
          name: "tiger-fact-edit",
          params: { id: this.tigerFactData.id },
        });
      }
    });
    if (this.tigerFactData.id) {
      this["tigerFacts/getById"](this.tigerFactData.id);
    }
  },
  beforeDestroy() {
    if (this.storeSubscription) {
      this.storeSubscription();
    }
  },
  methods: {
    ...mapActions([
      "tigerFacts/create",
      "tigerFacts/update",
      "tigerFacts/getById",
    ]),

    requestTigerFactUpdate: debounce(function () {
      this.updateTigerFact();
    }, 500),

    updateTigerFact() {
      let method;
      if (this.tigerFactData.id) {
        method = "tigerFacts/update";
      } else {
        method = "tigerFacts/create";
      }
      const dataToSend = Object.assign({}, this.tigerFactData);
      this[method](dataToSend);
    },
    initTigerFactForm(data) {
      this.tigerFactData.id = data.id;
      this.tigerFactData.lead = data.lead;
      this.tigerFactData.text = data.text;
    },
  },
};
</script>
